<button class="cell shrink button secondary color" type="button" (click)="openExportUrl()">
    {{'export.collaborator.button' | translate}}
</button>
<hr>
<input-file [(ngModel)]="file" (ngModelChange)="uploadFile($event)"
            [text]="uploadText"
            [success]="success"
            [disabled]="disableInputFile || !hasWriteRole"
            [noMargin]="true"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            class="cell"
            [ngClass]="{'small-12 xlarge-10': !isFlex, 'auto _fullContent': isFlex}">
</input-file>
