<div class="cell auto app-view-content">
    <div class="grid-y cell auto main-container has-space">
        <div class="grid-x cell shrink align-justify" [hidden]="!containBlockingInvoice">
            <div class="info alert-info cell auto common-block force-space-bottom-0">
                {{ 'media.invoiceUnpaid' | translate }}
            </div>
        </div>

        <div class="grid-x cell shrink align-justify space-bottom-8 space-top-8">
            <h3 class="grid-x align-middle secondary block-title">
                {{ 'media.title' |translate }}
            </h3>

            <a type="button" class="cell shrink button secondary" [routerLink]="'/main/media/followOrder'">
                {{ 'media.followOrder.title' | translate }}
            </a>
        </div>
        <fieldset>
            <legend>{{ 'media.importExport' | translate }}</legend>
            <import-export
                [exportUrl]="'/api/foAccounts/' + foAccountRef + '/media/export/xlsx' + getQueryParamsFromCriteria()"
                [uploadText]="'import.media.button' | translate"
                [exportType]="'media'"
                [importMaxErrorToReport]="11"
                [createImportRequestReportObs]="createMediaImportRequestReportObs"
                [fetchImportRequestReportObs]="fetchImportRequestReportObs"
                [importRequestObs]="importRequestObs"
                [hasWriteRole]="hasPassWriteRole"
                (importRequestProcessingEnded)="searchMedias()"
            ></import-export>
        </fieldset>

        <form (submit)="searchMedias()">
            <fieldset>
                <legend>{{ 'media.filter.title' | translate }}</legend>
                <form class="grid-x cell shrink align-justify align-bottom grid-padding-x space-bottom-8 filter-bloc">
                    <div class="cell auto">
                        <span>{{ 'media.filter.code' | translate }}</span>
                        <input type="text"
                               [(ngModel)]="criteria.code"
                               [placeholder]="'media.placeholder.code' | translate"
                               name="code">
                    </div>
                    <div class="cell auto">
                        <span>{{ 'media.filter.collaborator' | translate }}</span>
                        <ng-select
                            name="subscriberRef"
                            [closeOnSelect]="true"
                            [multiple]="false"
                            [placeholder]="!criteria?.subscriberRef ? ('media.placeholder.collaborator' | translate) : '' "
                            [(ngModel)]="criteria.subscriberRef"
                            [bindLabel]="'name'"
                            [bindValue]="'id'"
                            [items]="subscriberIdLabels">
                        </ng-select>
                    </div>
                    <div class="cell auto">
                        <span>{{ 'media.filter.vehicle' | translate }}</span>
                        <ng-select
                            name="vehicleRef"
                            [closeOnSelect]="true"
                            [multiple]="false"
                            [placeholder]="!criteria?.vehicleRef ? ('media.placeholder.vehicle' | translate) : '' "
                            [(ngModel)]="criteria.vehicleRef"
                            [bindLabel]="'name'"
                            [bindValue]="'id'"
                            [items]="vehicleIdLabels">
                        </ng-select>
                    </div>
                    <div class="cell auto">
                        <span>{{ 'media.filter.subscriptionPacks' | translate }}</span>
                        <ng-select
                            name="packSubscriptionNames"
                            [closeOnSelect]="false"
                            [multiple]="true"
                            [placeholder]="!criteria?.subscriptionPackRefs?.length ? ('media.placeholder.subscriptionPacks' | translate) : '' "
                            [(ngModel)]="criteria.subscriptionPackRefs"
                            [bindValue]="'id'"
                            [bindLabel]="'name'"
                            [items]="packSubscriptionIdLabels">
                        </ng-select>
                    </div>
                    <div class="cell auto">
                        <span>{{ 'media.filter.mediaAlias' | translate }}</span>
                        <input type="text"
                               [(ngModel)]="criteria.mediaAlias"
                               [placeholder]="'media.placeholder.mediaAlias' | translate"
                               name="mediaAlias">
                    </div>
                    <div class="grid-x cell shrink space-top-24">
                        <button class="cell small-24 medium-shrink button primary responsiveButton"
                                [iziviaLoading]="searching"
                                (click)="searchMedias()">
                            {{ 'media.search.button' | translate }}
                        </button>
                    </div>
                </form>
            </fieldset>
        </form>
        <div class="grid-x cell shrink align-justify align-middle card-divider space-bottom-8">
            <div class="grid-x cell auto small-up-3 space-left-24 space-right-24 align-center align-middle">
                <indicator class="cell" [img]="'passActivated'"
                           [title]="'media.activatedSubtitle' | translate">
                    <ng-container value>{{ mediaData?.activate | number }}
                    </ng-container>
                    <ng-container label>{{ plural(mediaData?.activate || 0, "media.activated.title") }}</ng-container>
                </indicator>
                <indicator class="cell" [img]="'passOrders'"
                           [title]="'media.orderedSubtitle' | translate">
                    <ng-container value>{{ mediaData?.order | number }}
                    </ng-container>
                    <ng-container label>{{ plural(mediaData?.order || 0, "media.orders.title") }}</ng-container>
                </indicator>
                <indicator class="cell" [img]="'passDisabled'"
                           [title]="'media.disabledSubtitle' | translate">
                    <ng-container value>{{ mediaData?.disabled | number }}
                    </ng-container>
                    <ng-container label>{{ plural(mediaData?.disabled || 0, "media.disabled.title") }}</ng-container>
                </indicator>
            </div>
            <label class="cell shrink checkbox">
                <input class="right" type="checkbox" [ngModel]="withInactivePass"
                       (ngModelChange)="switchInactivePass($event)" name="inactivePass"/>
                <span class="custom-checkbox"></span>
                <span class="label-choice">{{ 'media.inactivePass' | translate }}</span>
            </label>
        </div>

        <div *ngIf="displayLimitMessage"
             class="info cell auto common-block force-space-bottom-0 space-top-25">
            {{ 'media.search.limit' | translate }}
        </div>

        <div class="grid-y cell shrink space-top-25">
            <div class="cell small-24">
                <next-last-page [pageNumber]="_mediasPage" [maxPage]="lastPage"
                                (changePage)="getMediasFromPage($event)"></next-last-page>
                <table [hidden]="medias?.length <= 0">
                    <thead>
                    <tr>
                        <th></th>
                        <th>{{ 'media.table.number' | translate }}</th>
                        <th>{{ 'media.table.user' | translate }}</th>
                        <th>{{ 'media.table.vehicle.title' | translate }}</th>
                        <th>{{ 'media.table.subscription.subscription' | translate }}</th>
                        <th>{{ 'media.table.detail.detail' | translate }}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let media of mediasDisplayed; index as index; trackBy: trackByMediaItemId"
                        [hidden]="(media?.desactivated && !withInactivePass) || medias?.length === 0"
                        [ngClass]="{inactive: media.desactivated}">
                        <td><img class="media-img" [src]="'/api/download/'+media.visual.logoRef"></td>
                        <td>{{ media.code }}
                            <span *ngIf="media.status === MediaStatus.SUSPENDED">({{'media.disabled.suspended' | translate}})</span>
                        </td>
                        <td>
                            <div class="grid-x"
                                 [hidden]="inputStatus[mediaIndexForPage + index]?.isSubscriberChange">
                                <button class="button icon-btn primary"
                                        (click)="selectSubscriberChangeInput(mediaIndexForPage + index)"
                                        [hidden]="media?.desactivated">
                                    <i class="fas fa-pencil-alt"></i>
                                </button>
                                <div class="grid-y">
                                    <span>{{ media.userFirstName }} {{ media.userLastName }}</span>
                                    <span>{{ media.userMail }}</span>
                                </div>
                            </div>
                            <form class="grid-x align-middle"
                                  *ngIf="inputStatus[mediaIndexForPage + index]?.isSubscriberChange">
                                <label class="cell shrink">
                                    <suggest [name]="'subscriberRef' + (mediaIndexForPage + index)"
                                             [(ngModel)]="media.personRef"
                                             [results]="subscribers"
                                             [itemValueGetter]="getSubscriberId"
                                             [itemNameGetter]="getSubscriberName"
                                             (searchChange)="searchAndSetSubscribers($event)">
                                        <ng-template let-subscriber="item">
                                            {{ subscriber.firstName }} {{ subscriber.lastName }}
                                        </ng-template>
                                    </suggest>
                                </label>
                                <button class="cell shrink force-space-left-8 button small"
                                        (click)="cancelSubscriberEdition(mediaIndexForPage + index)"
                                        [disabled]="savingSubscriber">
                                    <i class="fas fa-times"></i>
                                </button>
                                <button class="cell shrink force-space-left-8 button small" type="submit"
                                        [iziviaLoading]="savingSubscriber"
                                        (click)="saveSubscriber(media.id, mediaIndexForPage + index)">
                                    <i class="fas fa-check"></i>
                                </button>
                            </form>
                        </td>
                        <td>
                            <div class="grid-x"
                                 [hidden]="inputStatus[mediaIndexForPage + index]?.isVehicleChange">
                                <button class="button icon-btn primary"
                                        (click)="selectVehicleChangeInput(mediaIndexForPage + index)"
                                        [hidden]="media?.desactivated">
                                    <i class="fas fa-pencil-alt"></i>
                                </button>
                                {{ media.vehicleLicenseNumber }}
                            </div>
                            <form class="grid-x align-middle"
                                  *ngIf="inputStatus[mediaIndexForPage + index]?.isVehicleChange">
                                <label class="cell shrink">
                                    <ng-select name="'vehicleRef' + (mediaIndexForPage + index)"
                                               class="custom"
                                               [multiple]="false"
                                               [closeOnSelect]="true"
                                               [(ngModel)]="media.vehicleRef"
                                               [bindLabel]="'licenseNumber'"
                                               [bindValue]="'id'"
                                               [loadingText]="'select.loading' | translate"
                                               [items]="vehicles"
                                               [hideSelected]="true"
                                    ></ng-select>
                                </label>
                                <button class="cell shrink force-space-left-8 button small"
                                        (click)="cancelVehicleEdition(mediaIndexForPage + index)"
                                        [disabled]="savingVehicle">
                                    <i class="fas fa-times"></i>
                                </button>
                                <button class="cell shrink force-space-left-8 button small" type="submit"
                                        [iziviaLoading]="savingVehicle"
                                        (click)="saveVehicle(media.id, mediaIndexForPage + index)">
                                    <i class="fas fa-check"></i>
                                </button>
                            </form>
                        </td>
                        <td class="tableWidth">
                            <div
                                [hidden]="inputStatus[mediaIndexForPage + index]?.isSubscriptionPacksChange">
                                <button class="button icon-btn primary"
                                        (click)="selectSubscriptionPacksChangeInput(mediaIndexForPage + index)"
                                        [hidden]="media?.desactivated">
                                    <i class="fas fa-pencil-alt"></i>
                                </button>
                                <span [innerHTML]="formatSubscriptionPack(media)"></span>
                            </div>
                            <form class="grid-x align-middle"
                                  *ngIf="inputStatus[mediaIndexForPage + index]?.isSubscriptionPacksChange">
                                <label class="cell auto">
                                    <ng-select
                                        *ngIf="inputStatus[mediaIndexForPage + index]?.acceptedSubscriptionPacks"
                                        name="subscriptions"
                                        [multiple]="true"
                                        [closeOnSelect]="false"
                                        [(ngModel)]="inputStatus[mediaIndexForPage + index].activatedOnSubscriptionPacks"
                                        [bindLabel]="'name'"
                                        [disabled]="subscriptionPacksLoading"
                                        [loadingText]="'select.loading' | translate"
                                        [notFoundText]="'media.subscriptionPack.select.notFoundText' | translate"
                                        [items]="inputStatus[mediaIndexForPage + index].acceptedSubscriptionPacks"></ng-select>
                                </label>
                                <button class="cell shrink force-space-left-8 button small"
                                        (click)="cancelSubscriptionPacksEdition(mediaIndexForPage + index)"
                                        [disabled]="savingSubscriptionPacks">
                                    <i class="fas fa-times"></i>
                                </button>
                                <button class="cell shrink force-space-left-8 button small" type="submit"
                                        [iziviaLoading]="savingSubscriptionPacks"
                                        (click)="saveSubscriptionPacks(media.id, mediaIndexForPage + index)">
                                    <i class="fas fa-check"></i>
                                </button>
                            </form>
                        </td>
                        <td>
                            <div [hidden]="inputStatus[mediaIndexForPage + index]?.isAliasChange">
                                <button (click)="showInput(mediaIndexForPage + index)"
                                        [hidden]="media?.desactivated"
                                        class="button icon-btn primary">
                                    <i class="fas fa-pencil-alt"></i>
                                </button>
                                {{ media.alias }}
                            </div>
                            <form [hidden]="!inputStatus[mediaIndexForPage + index]?.isAliasChange"
                                  class="grid-x align-middle">
                                <label class="cell shrink">
                                    <input [(ngModel)]="media.alias"
                                           [id]="'alias' + (mediaIndexForPage + index)"
                                           [name]="'alias' + (mediaIndexForPage + index)"
                                           class="small"
                                           type="text">
                                </label>
                                <button (click)="saveAlias(media.id, mediaIndexForPage + index)"
                                        class="cell shrink force-space-left-8 button small"
                                        type="submit">
                                    <i class="fas fa-check"></i>
                                </button>
                            </form>
                        </td>
                        <td style="text-align: right">
                            <button (click)="openDeactivateMediaPopup(media)"
                                    title="{{'media.table.button.deactivate' | translate}}"
                                    *ngIf="!media.desactivated"
                                    class="button icon-btn primary">
                                <i class="fas fa-times"></i>
                            </button>
                            <button *ngIf="media.desactivated && media.disableReason !== DisableReason.LOST_OR_STOLEN"
                                    (click)="activateMedia(media)"
                                    [iziviaLoading]="activatingMedia"
                                    title="{{'media.table.button.activate' | translate}}"
                                    class="button icon-btn primary">
                                <i class="fas fa-check"></i>
                            </button>

                        </td>
                    </tr>
                    </tbody>
                </table>
                <next-last-page [pageNumber]="_mediasPage" [maxPage]="lastPage"
                                (changePage)="getMediasFromPage($event)"></next-last-page>
            </div>
        </div>

        <div class="grid-y cell auto align-center align-middle message-empty"
             [hidden]=" medias.length > 0 &&((medias[0]?.desactivated && withInactivePass ) || !medias[0]?.desactivated)">
            <img class="message-empty-icon" src="/assets/svg/no-search.svg"/>
            <span class="message-empty-label">{{ 'media.table.noResult' | translate }}</span>
        </div>

        <div class="cell shrink grid-x align-bottom align-justify space-bottom-10 fix-action-bar">
            <div class="grid-x cell auto align-right action-bar add-space-block">
                <div class="grid-x align-center">
                    <a class="cell shrink button secondary color force-space-right-8"
                       *ngIf="hasCollaboratorsWriteRole"
                       [routerLink]="'/main/configuration/collaborators'">
                        {{ 'media.button.subscriber' | translate }}</a>

                    <a *ngIf="cannotOrderMedia()"
                       class="cell shrink button secondary color force-space-right-8 disabled">
                        {{ 'media.button.order' | translate }}
                    </a>
                    <a *ngIf="!cannotOrderMedia()"
                       class="cell shrink button secondary color force-space-right-8"
                       (click)="goToMediaOrder()">
                        {{ 'media.button.order' | translate }}
                    </a>

                    <a class="cell shrink button validate"
                       *ngIf="userHasPackSubscriptions"
                       (click)="goToMediaActivation()">
                        {{ 'media.button.activate' | translate }}</a>

                    <a class="cell shrink button validate disabled"
                       *ngIf="!userHasPackSubscriptions">
                        {{ 'media.button.activate' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</div>
<izivia-popup #deactivateMediaPopup [size]="'medium'">
    <media-deactivation *ngIf="deactivateMediaPopup.isOpened()"
                        [mediaToDeactivate]="mediaToDeactivate"
                        [foAccountRef]="foAccountRef"
                        (mediaDeactivated)="mediaDeactivated($event)"
                        (cancel)="deactivateMediaPopup.close()">
    </media-deactivation>
</izivia-popup>
