import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
    AbstractControl,
    AsyncValidator,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    NG_ASYNC_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators
} from '@angular/forms';
import {
    DetailedCollaboratorStatus,
    EmailValidator,
    FrontFoAccountHttpService,
    MediaFleetItemDto,
    MediaItem,
    Responsibility
} from 'lib-front';
import {last, Observable, of, pairwise, startWith, Subscription} from 'rxjs';
import {filter, map, take, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'collaborator-form[responsibilities]',
    templateUrl: './collaborator-form.component.html',
    styleUrls: ['./collaborator-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CollaboratorFormComponent),
            multi: true
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => CollaboratorFormComponent),
            multi: true
        }
    ]
})
export class CollaboratorFormComponent implements OnInit, OnDestroy, ControlValueAccessor, AsyncValidator {
    @Input('mediasObs') set mediaObsInput(mediasObs: Observable<MediaFleetItemDto[]>) {
        this.mediasObs = mediasObs;
        this.setMediaObservable();
    }
    @Input() hasCollaboratorsWriteRole: boolean;
    @Input() hasSubscriberRolesReadRole: boolean;
    @Input() showResponsibility: boolean;
    @Input() isRefundManager: boolean;
    @Input() get collaboratorWithSupervisedStationCount(): number {
        return this._collaboratorWithSupervisedStationCount;
    };
    set collaboratorWithSupervisedStationCount(collaboratorWithSupervisedStationCount: number) {
        this._collaboratorWithSupervisedStationCount = collaboratorWithSupervisedStationCount;
    }

    @Input() get supervisedStationCount(): number {
        return this._supervisedStationCount;
    }

    set supervisedStationCount(supervisedStationCount: number) {
        this._supervisedStationCount = supervisedStationCount;
    }

    @Input() medias: MediaItem[];
    @Input() responsibilities: Responsibility[];
    @Output() withSupervisedStationChanged: EventEmitter<number> = new EventEmitter<number>();
    @Input() public searchLimitMedia: number = 100;
    @Input() public addingUser: boolean;
    @Input() public deletingUser: boolean;
    @Input() public collaboratorEntry: AbstractControl;
    @Input() public status: DetailedCollaboratorStatus;

    @Output() addUserToFleetRequested: EventEmitter<void> = new EventEmitter();
    @Output() redirectedAndFilteredOnPermissionTab: EventEmitter<void> = new EventEmitter();
    @Output() collaboratorDeleted: EventEmitter<void> = new EventEmitter();

    public isRefundCollaborator: boolean = false;
    public mediaSearchLimitReached = false;

    Mode = Mode;

    mediasObs: Observable<MediaFleetItemDto[]>;

    mediasCanActivateObs: Observable<MediaFleetItemDto[]>;
    mode = Mode.CREATE;
    collaboratorId;

    form: UntypedFormGroup;
    _collaboratorWithSupervisedStationCount: number;
    _supervisedStationCount: number;

    private withSupervisedStationSubscription: Subscription;

    private onChangeCallback = (account: CollaboratorForm) => {
    };
    private onTouchedCallback = () => {
    };

    constructor(private fb: UntypedFormBuilder, private foAccountHttpService: FrontFoAccountHttpService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        const emailValidator = new EmailValidator();

        this.form = this.fb.group({
            name: ['', Validators.required],
            lastName: ['', Validators.required],
            responsibility: ['', Validators.required],
            withSupervisedStation: [false, Validators.required],
            isRefundCollaborator: [false, Validators.required],
            medias: [[]],
            email: this.fb.control('', [Validators.required, emailValidator.validate]),
            status: []
        });

        if (!this.hasCollaboratorsWriteRole) {
            this.form.disable();
        }

        this.setFormMode();

        this.form.valueChanges.pipe(
            map(() => this.form.getRawValue() as CollaboratorForm),
            tap(value => value.mode = this.mode),
            tap(value => value._id = this.collaboratorId),
            tap(value => this.isRefundCollaborator = value.isRefundCollaborator),
            tap(value => this.onChangeCallback(value))
        ).subscribe();

        this.withSupervisedStationSubscription = this.form.get('withSupervisedStation').valueChanges
            .pipe(startWith(null), pairwise())
            .subscribe(([oldValue, value]) => {
                if (oldValue !== null && oldValue !== value) {
                    if (value) {
                        this.withSupervisedStationChanged.next(this._collaboratorWithSupervisedStationCount + 1);
                    } else {
                        this.withSupervisedStationChanged.next(this._collaboratorWithSupervisedStationCount - 1);
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.withSupervisedStationSubscription.unsubscribe();
    }

    private setFormMode() {
        if (this.mode === Mode.CREATE) {
            if (this.form.get('media') === null) {
                this.form.addControl('media', this.fb.control(''));
            }
            this.setMediaObservable();
        } else {
            if (this.form.get('media')) {
                this.form.removeControl('media');
            }
            this.form.get('email').disable();
            this.setMediaObservable();
        }
    }

    private setMediaObservable() {
        if (this.mediasObs) {
            if (this.mode === Mode.CREATE) {
                this.route.data.subscribe(data => {
                    this.mediasCanActivateObs = this.mediasObs
                        .pipe(
                            tap(medias =>
                                this.mediaSearchLimitReached = medias && medias.length === this.searchLimitMedia),
                            map(medias =>
                                medias.filter(media =>
                                    media.personRef === data.user.user._id && !media.desactivated)
                            )
                        );
                });
            }
        }
    }

    public createLabelMedia(media: MediaFleetItemDto): string {
        return !!media.alias ? media.alias + ' : ' + media.code : media.code;
    }

    public writeValue(collaborator: CollaboratorForm): void {
        if (collaborator && collaborator.mode) {
            this.mode = collaborator.mode;
        }

        this.setFormMode();
        this.form.patchValue(collaborator || {});

        if (collaborator) {
            this.collaboratorId = collaborator._id;
        }

        this.form.updateValueAndValidity();
    }

    public validate(c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        if (this.form.pending) {
            return this.form.statusChanges.pipe(
                filter(value => value !== 'PENDING'),
                take(1),
                map(value => value === 'VALID' ? null : {'account': true})
            );
        } else {
            return of(this.form.valid ? null : {'account': true});
        }
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    trackByMediaId(index: number, media: MediaFleetItemDto) {
        return media?.id ?? index;
    }

    protected readonly Responsibility = Responsibility;
    protected readonly last = last;

    addUserToFleetRequest() {
        this.addUserToFleetRequested.emit();
    }

    redirectAndFilterOnPermissionTab() {
        this.redirectedAndFilteredOnPermissionTab.emit();
    }

    deleteCollaborator() {
        this.collaboratorDeleted.emit();
    }
}

export interface CollaboratorForm {
    _id?: string;
    name: string;
    lastName: string;
    email: string;
    medias?: MediaItem[];
    responsibility: Responsibility;
    withSupervisedStation: boolean;
    isRefundCollaborator: boolean;
    mode: Mode;
    status: DetailedCollaboratorStatus;
}

export enum Mode {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE'
}
