import {Component, EventEmitter, forwardRef, Input, OnDestroy, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR,} from '@angular/forms';
import {
    FileReference,
    FleetImportGlobalError,
    FleetImportLineError,
    FleetImportRequestReport,
    FleetImportRequestReportProcessing, FrontAccountHttpService,
    FrontUploadHttpService, LastFleetImport, switchTap
} from 'lib-front';
import {NotificationService} from '../../services/utils/notification.service';
import {finalize, first, map, switchMap, tap} from 'rxjs/operators';
import {EMPTY, interval, Observable, of, Subscription, timer} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../services/utils/alert.service';
import {ProgressingImportService} from '../../services/events/progressingImport.service';
import {ActivatedRoute} from '@angular/router';
import {CurrentUserContextService} from '../../services/business/currentUserContext.service';

@Component({
    selector: 'import-export',
    templateUrl: './import-export.component.html',
    styleUrls: ['./import-export.component.scss'],
    host: {class: 'cell grid-x'},
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ImportExportComponent),
        multi: true
    }]
})
export class ImportExportComponent implements OnDestroy {
    private readonly maxErrorToDisplay = Math.max((this.importMaxErrorToReport || 11) - 1, 0);

    @Input() exportUrl: string;
    @Input() uploadText: string;
    @Input() exportType: 'collaborator' | 'media' | 'vehicle';
    // Set undefined or null to avoid limiting error report
    @Input()
    set importMaxErrorToReport(max: number) {
        this._importMaxErrorToReport = max;
    }
    get importMaxErrorToReport(): number {
        return this._importMaxErrorToReport;
    }

    @Input() createImportRequestReportObs: (fileId: string, maxErrorToReport: number) => Observable<string>;
    @Input() fetchImportRequestReportObs: (fleetImportRequestReportId: string) => Observable<FleetImportRequestReportProcessing>;
    @Input() importRequestObs: (collaboratorImportRequestId: string) => Observable<void>;
    @Input() hasWriteRole: boolean;
    @Input() isFlex: boolean = false;
    @Input()
    set disableInputFile(disableInputFile: boolean) {
        this._disableInputFile = disableInputFile;
    }

    get disableInputFile(): boolean {
        return this._disableInputFile;
    }

    @Output() importRequestProcessingEnded = new EventEmitter<void>();
    @Output() importEnded = new EventEmitter<void>();

    file: FileList;
    _disableInputFile: boolean = false;
    _importMaxErrorToReport: number;
    success: boolean = false;
    foAccountRef: string;

    importProgressSubscription: Subscription;

    private readonly route: ActivatedRoute;

    private readonly accountHttpService: FrontAccountHttpService;

    private readonly currentUserContextService: CurrentUserContextService;

    constructor(private uploadHttpService: FrontUploadHttpService,
        private translateService: TranslateService,
        private alertService: AlertService,
        private notificationService: NotificationService,
        private progressingImportService: ProgressingImportService,
        route: ActivatedRoute,
        accountHttpService: FrontAccountHttpService,
        currentUserContextService: CurrentUserContextService) {
        this.currentUserContextService = currentUserContextService;
        this.accountHttpService = accountHttpService;
        this.route = route;

        route.data.pipe(
            tap(data => this.foAccountRef = currentUserContextService.getCurrentFoAccountId())
        ).subscribe(() => {});
    }

    ngOnDestroy(): void {
        if (this.importProgressSubscription) {
            this.importProgressSubscription.unsubscribe();
        }
    }

    public openExportUrl() {
        window.open(this.exportUrl);
    }

    public uploadFile(files: FileList) {
        if (files && files.length) {
            this.uploadHttpService.upload(files[0], files[0].name)
                .subscribe(
                    fileReference => this.tryImportFile(fileReference),
                    () => {
                        this.notificationService.error('upload.error');
                        this.success = false;
                    }
                );
        }
    }

    public tryImportFile(fileReference: FileReference) {
        if (fileReference) {
            this._disableInputFile = true;
            this.createImportRequestReportObs(fileReference.fileId, this.importMaxErrorToReport)
                .pipe(
                    map(fleetImportRequestId => fleetImportRequestId.trim()),
                    tap(() => this.notificationService.success('import.xlsx.processingReport'))
                )
                .subscribe(
                    fleetImportRequestId => this.createTimerAndFetchImportState(fleetImportRequestId),
                    error => {
                        console.error(error);
                        this.file = null;
                        this._disableInputFile = false;
                        this.success = false;
                        this.notificationService.error('import.xlsx.error.common');
                    });
        }
    }

    createTimerAndFetchImportState(fleetImportRequestId: string): any {
        timer(2_000, 10_000)
            .pipe(
                switchMap(() => this.fetchImportRequestReportObs(fleetImportRequestId)),
                first(report => report.generated),
                map(fleetImportRequestReport => fleetImportRequestReport.fleetImportRequestReport),
                finalize(() => this.file = null),
                finalize(() => this._disableInputFile = false)
            )
            .subscribe({
                next: importRequestReport => {
                    this.importEnded.emit();

                    this.showImportReportAndStartImport(importRequestReport, importRequestReport.importRequestId);
                },
                error: error => {
                    console.error(error);
                    this.file = null;
                    this._disableInputFile = false;
                    this.success = false;
                    this.notificationService.error('import.xlsx.error.common');

                    this.importEnded.emit();
                }
            });
    }

    private showImportReportAndStartImport(importRequestReport: FleetImportRequestReport, importRequestId: string) {
        this.showImportRequestReport(importRequestReport)
            .pipe(
                tap(confirm => {
                    if (confirm) {
                        this.importRequestObs(importRequestId)
                            .pipe(
                                switchMap(() => this.progressingImportService.searchProgressingImport())
                            )
                            .subscribe();
                    }
                    return EMPTY;
                })
            ).subscribe({
                next: confirm => {
                    if (confirm) {
                        this.notificationService.success('import.xlsx.processing');
                        this.importProgressSubscription = interval(5_000)
                            .pipe(
                                switchMap(() => this.progressingImportService.searchProgressingImport()),
                                switchMap(() => this.progressingImportService.currentImportIds$),
                                first(currentImportIds => !new Set(currentImportIds).has(importRequestId))
                            ).subscribe(() => {
                                this.importRequestProcessingEnded.emit();
                                this.importEnded.emit();
                            });
                    }
                },
                error: err => {
                    let message;

                    // Try to get key from server error
                    if (err && err.error && err.error.labelKey) {
                        const translatedMessage = this.translateService.instant(err.error.labelKey);
                        if (err.error.labelKey !== translatedMessage) {
                            message = translatedMessage;
                        }
                    }

                    this.notificationService.error(message || 'import.xlsx.error.common');
                    this.importEnded.emit();
                }
            });
    }

    private showImportRequestReport(importRequest: FleetImportRequestReport): Observable<boolean> {
        if (importRequest.importLineReports.length || importRequest.globalErrors.length) {
            // If request has errors, show
            const title = this.translateService.instant(`import.${this.exportType}.alert.error.title`);
            const downloadLabel = this.translateService.instant('import.collaborator.downloadErrorReportModal');

            let message: string = '';

            if (this.exportType === 'collaborator') {
                message += `
                    <div class="grid-x align-center">
                        <a href="/api/foAccounts/${this.foAccountRef}/collaborators/import/report/last"
                           target="_blank"
                           class="cell shrink button secondary tiny _withText">
                            <span class="fas fa-download"></span>
                            <span>${downloadLabel}</span>
                        </a>
                    </div>
                    <br>
                    <hr>
                    <br>
                `;
            }

            message += '<div style="font-weight: initial; color: initial; text-align: initial">';

            if (importRequest.globalErrors.length) {
                importRequest.globalErrors.forEach(error => message += this.translateGlobalErrorMessage(error));
            } else {
                const linesErrors = importRequest.importLineReports
                    .map(line => line.errors.map(error => ({index: line.index, error: error})))
                    .reduce((previousValue, currentValue) => previousValue.concat(currentValue));

                const hasMoreErrors = this.maxErrorToDisplay < linesErrors.length;
                const linesToDisplay = hasMoreErrors
                    ? linesErrors.splice(0, this.maxErrorToDisplay)
                    : linesErrors;

                linesToDisplay.forEach(line => message += this.translateErrorMessage(line.error, line.index));

                if (hasMoreErrors) {
                    message += '<p style="font-style: italic">';
                    message += this.translateService.instant('import.error.more');
                    message += '</p>';
                }
                message += '</div>';
                message += '<br>';
                message += '<hr>';
            }


            return this.alertService.show(message, title).pipe(map(() => false));
        } else if (!(importRequest.nbItemsToCreate || importRequest.nbItemsToUpdate || importRequest.nbItemsToDelete)) {
            // no creation, update or deletion
            this.notificationService.error(`import.${this.exportType}.error.empty`);
            return of(false);
        } else {
            const title = this.translateService.instant(`import.${this.exportType}.alert.confirm.title`);

            let message = '';
            message += this.translateService.instant(`import.${this.exportType}.alert.confirm.message.action`);
            message += '<br>';
            message += '<p>';
            message += this.translateConfirmMessage('create', importRequest.nbItemsToCreate);
            message += this.translateConfirmMessage('update', importRequest.nbItemsToUpdate);
            message += this.translateConfirmMessage('delete', importRequest.nbItemsToDelete);
            message += '</p>';

            if (importRequest.subscriptionPackNamesWithNoLimitNotActivatedOnAtLeastOneMedia.length > 0) {
                message += '<br>';
                message += '<p style="color: #005BBB">';
                message += this.translateService.instant('import.media.alert.confirm.message.notActivatedSubscriptionPack');
                message += '</p>';
                message += '<ul style="color: #005BBB; list-style-type: none">';
                importRequest.subscriptionPackNamesWithNoLimitNotActivatedOnAtLeastOneMedia.forEach(
                    subscriptionPackName => message += `<li>${subscriptionPackName}</li>`
                );
                message += '</ul>';
            }

            this.success = true;
            return this.alertService.confirm(message, title);
        }
    }

    private translateConfirmMessage(type: 'create' | 'update' | 'delete', count: number) {
        const singleOrPlural = count > 1 ? 'plurals' : 'single';
        const messageKey = `import.${this.exportType}.alert.confirm.message.${type}.${singleOrPlural}`;

        return this.translateService.instant(messageKey, {count: count}) + '<br>';
    }

    private translateErrorMessage(error: FleetImportLineError, index: number) {
        let message = '<p>';
        message += this.translateService.instant(`import.${this.exportType}.alert.error.field.${error.field}`, {index});
        message += ' ';
        message += this.translateService.instant(`import.${this.exportType}.alert.error.type.${error.type}`, error.params);
        message += '</p>';
        return message;
    }

    private translateGlobalErrorMessage(error: FleetImportGlobalError) {
        let message = '<p>';
        message += this.translateService.instant(`import.global.error.${error.type}.message`);
        message += ' ';
        message += this.translateService.instant(`import.global.error.${error.type}.more`, error.params);
        message += '</p>';
        return message;
    }
}
