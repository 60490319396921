<form #myForm="ngForm" (submit)="search()">
    <fieldset>
        <legend>{{'chargeAreas.filter.filters' | translate}}</legend>

        <form class="grid-x cell shrink align-justify align-bottom grid-padding-x space-bottom-8 filter-bloc">
                <div class="cell small-6">
                    <label class="cell small-24 xlarge-8 xxlarge-10 search-label with-label force-inner-space-left-0 inputSearch"
                        [ngClass]="{validate : criteria.station}">
                        <span>{{'chargeAreas.filter.station' | translate}}</span>
                        <input [(ngModel)]="criteria.station"
                               [placeholder]="'chargeAreas.filter.stationPlaceholder' | translate"
                               class="search"
                               name="station" type="text">
                        <i class="fas fa-search search-icon"></i>
                    </label>
                </div>
                <div class="form-group cell auto"
                     [ngClass]="{validate : criteria.status}">
                    <span class="grid-x">
                        {{'chargeAreas.filter.status' | translate}}
                    </span>
                    <ng-select
                        name="status"
                        [closeOnSelect]="false"
                        [multiple]="true"
                        [placeholder]="!criteria?.status?.length ? ('chargeAreas.filter.statusPlaceholder' | translate) : '' "
                        [bindValue]="'status'"
                        [(ngModel)]="criteria.status"
                        [items]="availableStatus">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="custom-control custom-checkbox filterContent-text">
                                <input type="checkbox" [ngModel]="item$.selected" class="custom-control-input"
                                       [name]="item"/>
                                <span class="custom-checkbox"></span>
                                <span
                                    class="form-check-label ml-2 custom-control-label space-left-8">{{'stations.status.' + item | translate}}</span>
                            </div>

                        </ng-template>
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span class="ng-value-icon right"
                                  (click)="clear(item)">×<b>{{'stations.status.' + item | translate }}</b></span>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="cell auto"
                     [ngClass]="{validate : criteria.plugs}">
                    <span class="grid-x">
                        {{'chargeAreas.filter.plugType' | translate}}
                    </span>
                    <ng-select name="plugs"
                               [closeOnSelect]="false"
                               [multiple]="true"
                               [bindValue]="'name'"
                               [placeholder]="!criteria?.plugs?.length ? ('chargeAreas.filter.plugPlaceholder' | translate) : '' "
                               [(ngModel)]="criteria.plugs"
                               [items]="availablePlugTypes">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="custom-control custom-checkbox filterContent-text">
                                <input type="checkbox" [ngModel]="item$.selected" class="custom-control-input"
                                       [name]="item.name"/>
                                <span class="custom-checkbox"></span>
                                <span
                                    class="form-check-label ml-2 custom-control-label space-left-8 space-right-8">{{'plugType.' + item.name | translate}}</span>
                                <img height="15" width="15" [src]="'/api/download/' + item?.iconRef"/>
                            </div>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span class="ng-value-icon right" (click)="clear(item)">×
                                <b>{{'plugType.' + item.name | translate}}</b>
                            </span>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="cell auto"
                     [ngClass]="{validate : criteria.chargeAreas}">
                    <span class="grid-x">
                        {{'chargeAreas.filter.parcs' | translate}}
                    </span>
                    <ng-select
                        name="parcs"
                        [closeOnSelect]="false"
                        [placeholder]="!criteria?.chargeAreas?.length ? ('chargeAreas.filter.chargeAreaPlaceholder' | translate) : '' "
                        [multiple]="true"
                        [(ngModel)]="criteria.chargeAreas"
                        [bindValue]="'id'"
                        [items]="chargeAreas"
                        [searchFn]="filterChargeAreaOptions">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <div class="custom-control custom-checkbox filterContent-text">
                                <input type="checkbox"
                                       class="custom-control-input"
                                       [ngModel]="item$.selected"
                                       [name]="item.id"/>
                                <span class="custom-checkbox"></span>
                                <span class="form-check-label ml-2 custom-control-label space-left-8">
                                    {{item.name}}
                                </span>
                            </div>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span class="ng-value-icon right"
                                  (click)="clear(item)">×<b>{{item.name}}</b></span>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="grid-x cell shrink space-top-24">
                    <button (click)="search()" class="cell small-24 medium-shrink button primary responsiveButton">
                        {{'chargeConsumptions.search.button' | translate}}
                    </button>
                </div>
        </form>
    </fieldset>
</form>

