<div [formGroup]="form" xmlns="http://www.w3.org/1999/html" class="has-space grid-y main-container">
    <div class="small-8 grid-x grid-margin-x align-left space-top-8 space-bottom-12">
        <button class="cell shrink button secondary color"
                (click)="goToFleetList()">
            {{'fleet.vehicle.back' | translate}}
        </button>
    </div>

    <div>
        <div class="grid-x vehicleForm">
            <h5 *ngIf="!isEdit"
                class="cell shrink vehicleForm-title">
                {{'fleet.vehicle.licenseNumber.detail' | translate: {licenseNumber: vehicle.licenseNumber} }}
            </h5>
            <div *ngIf="isEdit" class="grid-x">
                <h5 class="vehicleForm-edit-title required"> {{'fleet.vehicle.licenseNumber.title' | translate }} </h5>
                <input class="vehicleForm-inputNumber" formControlName="licenseNumber" type="text">
            </div>
        </div>
    </div>

    <div class="cell vehicleForm-content">
        <div class="error note"
             *ngIf="licenseNumberCtrl.hasError('required') && licenseNumberCtrl.touched">
            {{'fleet.vehicle.licenseNumber.required' | translate}}
        </div>
        <div class="error note"
             *ngIf="licenseNumberCtrl.hasError('notUnique') && licenseNumberCtrl.touched">
            {{'fleet.vehicle.licenseNumber.alreadyExists' | translate}}
        </div>

        <div class="grid-x small-up-4 grid-padding-x margin-16">
            <ng-container formGroupName="vehicleVersion">
                <div class="cell">
                    <label>
                        <span class="vehicleForm-content_title">{{'fleet.vehicle.make.title' | translate}}</span>
                        <i *ngIf="isRigeUser" class="required fa fa-asterisk"></i>
                    </label>
                    <label *ngIf="!isEdit">
                        <span class="vehicleForm-content_text">{{ vehicle.vehicleVersion?.make }}</span>
                    </label>
                    <div *ngIf="isEdit">
                        <select formControlName="make">
                            <option value="" selected [disabled]="isRigeUser">
                                {{'fleet.vehicle.make.default' | translate}}
                            </option>
                            <option [value]="vehicleMake.name"
                                    *ngFor="let vehicleMake of vehicleMakes; trackBy: trackByName">{{vehicleMake.name}}</option>
                        </select>
                        <span class="error note"
                              *ngIf="!makeCtrl.valid && (makeCtrl.touched || makeCtrl.dirty)">
                                            {{'fleet.vehicle.make.required' | translate}}
                                </span>
                    </div>
                </div>
                <div class="cell">
                    <label [ngClass]="{'required': isEdit}">
                        <span class="vehicleForm-content_title">
                            {{'fleet.vehicle.model.title' | translate}}
                        </span>
                        <i *ngIf="isRigeUser" class="required fa fa-asterisk"></i>
                    </label>
                    <label *ngIf="!isEdit">
                        <span class="vehicleForm-content_text">{{vehicle.vehicleVersion?.model}}</span>
                    </label>
                    <div *ngIf="isEdit">
                        <select formControlName="model">
                            <option value="" selected [disabled]="isRigeUser">
                                {{'fleet.vehicle.model.default' | translate }}
                            </option>
                            <option [value]="vehicleModel.name"
                                    *ngFor="let vehicleModel of vehicleModels; trackBy: trackByName">
                                {{vehicleModel | fleetVehicleModelFormatter}}
                            </option>
                        </select>
                        <span class="error note"
                              *ngIf="!vehicleVersionForm.controls.model.valid &&
                                            (vehicleVersionForm.controls.model.touched || vehicleVersionForm.controls.model.dirty)">
                                                {{'fleet.vehicle.model.required' | translate}}
                                </span>
                    </div>
                </div>
                <div class="cell">
                    <label>
                        <span class="vehicleForm-content_title">{{'fleet.vehicle.version.title' | translate}}</span>
                    </label>
                    <label *ngIf="!isEdit">
                        <span class="vehicleForm-content_text">
                            <ng-container *ngIf="vehicle.vehicleVersion && vehicle.vehicleVersion.version !== ''; else standardVersion">
                                {{ vehicle.vehicleVersion.version }}
                            </ng-container>
                        </span>
                    </label>
                    <div *ngIf="isEdit">
                        <select formControlName="version">
                            <option value="initial" [disabled]="isRigeUser">
                                {{'fleet.vehicle.version.default' | translate}}
                            </option>
                            <option [value]="vehicleVersion.version"
                                    *ngFor="let vehicleVersion of vehicleVersions; trackBy: trackByVehicleVersionId">
                                {{vehicleVersion | fleetVehicleVersionFormatter}}
                            </option>
                        </select>
                    </div>
                    <ng-template #standardVersion>
                        {{'vehicle.version.empty' | translate}}
                    </ng-template>
                </div>
            </ng-container>
            <div class="cell" *ngIf="vehicle.mediaUsages">
                <label *ngIf="!isRigeUser || (isRigeUser && vehicle.mediaUsages.length === 0)">
                    <span class="vehicleForm-content_title">{{'fleet.vehicle.media.title' | translate}}</span>
                </label>
                <label *ngIf="isRigeUser && vehicle.mediaUsages.length && isVehicleMediaAssociatedInDreevProvider(vehicle, vehicle.mediaUsages[0].mediaId)">{{'fleet.vehicle.media.dreev.associated.success' | translate}}</label>
                <label *ngIf="isRigeUser && vehicle.mediaUsages.length && !isVehicleMediaAssociatedInDreevProvider(vehicle, vehicle.mediaUsages[0].mediaId)">{{'fleet.vehicle.media.dreev.associated.error' | translate}}</label>
                <label *ngIf="!isEdit">
                    <ng-container *ngIf="vehicle.mediaUsages.length; else noMedia">
                        <a [routerLink]="'/main/media'" [queryParams]="{licenseNumber: vehicle.licenseNumber}">
                            <ng-container *ngIf="vehicle.mediaUsages.length === 1">
                                {{vehicle.mediaUsages[0].publicCode}}
                            </ng-container>
                            <ng-container *ngIf="vehicle.mediaUsages.length > 1">
                                {{'fleet.vehicle.media.owned' | translate: {numberPass: vehicle.mediaUsages.length} }}
                            </ng-container>
                        </a>
                    </ng-container>
                    <ng-template #noMedia>
                        <p class="input-read-only fitHeight">{{'fleet.vehicle.media.not.owned' | translate}}</p>
                    </ng-template>
                </label>
                <ng-container *ngIf="isEdit">
                    <ng-select *ngIf="mediaCanActivateExist; else noActivateMedia"
                               formControlName="media"
                               (search)="searchMedias($event.term)"
                               (clear)="searchMedias(null)"
                               [bindLabel]="'publicCode'"
                               [placeholder]="'fleet.vehicle.media.default' | translate"
                               [multiple]="true"
                               [items]="mediasCanActivate">
                    </ng-select>
                </ng-container>
                <ng-template #noActivateMedia>
                    <p class="input-read-only">{{'fleet.vehicle.no.media.to.activate' | translate}}</p>
                </ng-template>
            </div>
        </div>
        <div class="grid-x small-up-4 grid-padding-x space-top-24">
            <div class="cell">
                <label>
                    <span class="vehicleForm-content_title">{{'fleet.vehicle.carRegistrationDocument.title' | translate}}</span>
                </label>
                <label *ngIf="!isEdit">
                    <a class="vehicleForm-content_text"
                       [href]="'/api/download/' + vehicle.carRegistrationFile?.id">
                        {{ vehicle.carRegistrationFile?.name }}
                    </a>
                </label>

                <div *ngIf="isEdit && vehicle.carRegistrationFile != null"
                    class="grid-x grid-padding-x">
                    <a class="vehicleForm-content_text cell auto"
                       [href]="'/api/download/' + vehicle.carRegistrationFile.id">
                        <input type="text" [value]="vehicle.carRegistrationFile.name">
                    </a>
                    <button type="button"
                            class="button round small cell shrink"
                            [style]="{'margin-Eleft': '8px'}"
                            *ngIf="isEdit"
                            (click)="vehicle.carRegistrationFile = null">
                        <i class="icon-trash"></i>
                    </button>
                </div>
                <input-file *ngIf="isEdit && (vehicle.carRegistrationFile === undefined || vehicle.carRegistrationFile === null)" formControlName="carRegistrationFile"
                            text="{{'fleet.vehicle.carRegistrationDocument.selectFile' | translate}}"
                            [accept]="fileFormats">
                </input-file>
                <div class="error note"
                     *ngIf="carRegistrationDocumentCtrl.hasError('fileExtensionInvalid')">
                    {{'fleet.vehicle.carRegistrationDocument.fileExtensionInvalid' | translate}}
                </div>
            </div>
            <div class="cell">
                <label>
                    <span class="vehicleForm-content_title">{{'fleet.vehicle.vehicleIdentifierNumber.title' | translate}}</span>
                </label>
                <label *ngIf="!isEdit">
                    <span class="vehicleForm-content_text">{{ vehicle.vehicleIdentifierNumber }}</span>
                </label>
                <input type="text" *ngIf="isEdit" formControlName="vehicleIdentifierNumber">
                <div class="error note"
                     *ngIf="vehicleIdentifierNumberCtrl.hasError('maxlength') && vehicleIdentifierNumberCtrl.touched">
                    {{'fleet.vehicle.vehicleIdentifierNumber.maxLength' | translate}}
                </div>
                <div class="error note"
                     *ngIf="vehicleIdentifierNumberCtrl.hasError('notUnique') && vehicleIdentifierNumberCtrl.touched">
                    {{'fleet.vehicle.vehicleIdentifierNumber.alreadyExists' | translate}}
                </div>
            </div>
            <div class="cell">
                <label>
                    <span class="vehicleForm-content_title">{{'fleet.vehicle.vehicleUsageType.title' | translate}}</span>
                </label>
                <label *ngIf="!isEdit">
                    <span *ngIf="vehicle.vehicleUsageType" class="vehicleForm-content_text">
                        {{'fleet.vehicle.vehicleUsageType.'
                    + vehicle.vehicleUsageType | translate}}</span>
                </label>
                <select *ngIf="isEdit" formControlName="vehicleUsageType">
                    <option [value]="null" selected
                            disabled>{{'fleet.vehicle.vehicleUsageType.default' | translate}}</option>
                    <option [value]="type.value"
                            *ngFor="let type of VehicleUsageType | keyvalue: sortNull">{{('fleet.vehicle.vehicleUsageType.'
                        + type.value) | translate}}</option>
                </select>
            </div>
            <div *ngIf="isRigeUser" class="cell">
                <label>
                    <span class="vehicleForm-content_title">{{'fleet.vehicle.priority.title' | translate}}</span>
                </label>
                <label class="checkbox">
                    <input formControlName="priority" type="checkbox">
                    <span class="custom-checkbox"></span>
                </label>
            </div>
        </div>

        <div class="grid-x small-up-4 grid-padding-x space-top-16 space-bottom-32">
            <div class="cell">
                <label>
                    <span class="vehicleForm-content_title">{{'fleet.vehicle.vehicleAcquisitionType.title' | translate}}</span>
                </label>
                <label *ngIf="!isEdit">
                    <span *ngIf="vehicle.vehicleAcquisitionType" class="vehicleForm-content_text">
                        {{'fleet.vehicle.vehicleAcquisitionType.'
                    + vehicle.vehicleAcquisitionType | translate}}</span>
                </label>
                <select *ngIf="isEdit" formControlName="vehicleAcquisitionType">
                    <option [value]="null" disabled
                            selected>{{'fleet.vehicle.vehicleAcquisitionType.default' | translate}}</option>
                    <option *ngFor="let type of VehicleAcquisitionType | keyvalue"
                            [value]="type.value">{{('fleet.vehicle.vehicleAcquisitionType.'
                        + type.value) | translate}}</option>
                </select>
            </div>
            <div class="cell">
                <label>
                    <span class="vehicleForm-content_title">{{'fleet.vehicle.leasingPartner.title' | translate}}</span>
                </label>
                <label *ngIf="!isEdit">
                    <span class="vehicleForm-content_text">{{ vehicle.leasingPartner }}</span>
                </label>
                <input *ngIf="isEdit" formControlName="leasingPartner" type="text">
            </div>
            <div class="cell">
                <label>
                    <span class="vehicleForm-content_title">{{'fleet.vehicle.detail.title' | translate}}</span>
                </label>
                <label *ngIf="!isEdit">
                    <span class="vehicleForm-content_text">{{ vehicle.detail }}</span>
                </label>
                <input *ngIf="isEdit" formControlName="detail" type="text">
            </div>
            <div *ngIf="isRigeUser && isVehicleCreatedInDreevProvider()" class="cell">
                <label>{{'fleet.create.dreev.success' | translate}}</label>
                <button class="cell shrink button"
                        (click)="editPlanning()">{{'fleet.vehicle.chargePlanning.title' | translate}}</button>
            </div>
            <div *ngIf="isRigeUser && !isVehicleCreatedInDreevProvider()" class="cell">
                <label>{{'fleet.create.dreev.error' | translate}}</label>
            </div>
        </div>
    </div>

    <div class="small-8 grid-x grid-margin-x align-right space-top-8 space-bottom-12" *ngIf="mode === 'CREATE'">
        <button class="cell shrink button secondary color"
                [disabled]="isCreating || form.invalid"
                (click)="validateVehicleCreation()">
            {{'fleet.vehicle.validate' | translate}}
        </button>
    </div>
    <div class="small-8 grid-x grid-margin-x align-right space-top-8 space-bottom-12" *ngIf="!isEdit && mode === 'EDIT'">
        <button (click)="editVehicle()"
                class="button primary cell shrink marginRight-8">
            {{'fleet.vehicle.buttons.edit' | translate}}
        </button>
        <button (click)="deleteVehicle(vehicle)"
                class="button secondary cell shrink marginLeft-8">
            {{'fleet.vehicle.buttons.delete' | translate}}
        </button>
    </div>
    <div class="small-8 grid-x grid-margin-x align-right space-top-8 space-bottom-12" *ngIf="isEdit && mode === 'EDIT'">
        <button (click)="updateVehicle()"
                [disabled]="form.invalid"
                class="button primary cell shrink marginRight-8">
            {{'fleet.vehicle.buttons.save' | translate}}
        </button>
        <button (click)="cancelEditVehicle()"
                class="button secondary cell shrink">
            {{'fleet.vehicle.buttons.cancel' | translate}}
        </button>
    </div>
</div>
