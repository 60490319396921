import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CollaboratorForm, Mode} from '../../../../../components/collaborator-form/collaborator-form.component';
import {NotificationService} from '../../../../../services/utils/notification.service';
import {ActivatedRoute} from '@angular/router';
import {
    CurrentSubscriberRolesService,
    DetailedPersonDto,
    FrontFoAccountHttpService,
    FrontMediaHttpService,
    FrontPersonHttpService,
    IndexPaginationParams,
    Responsibility,
    SubscriberRoleLevel,
    SubscriberRoleType,
    buildRole,
    CollaboratorSearchCriteria
} from 'lib-front';
import {finalize, map, tap} from 'rxjs/operators';
import {CurrentUserContextService} from '../../../../../services/business/currentUserContext.service';
import {slice} from 'lodash-es';
@Component({
    selector: 'collaborator-configuration',
    templateUrl: './collaborator-configuration.component.html',
    styleUrls: ['./collaborator-configuration.component.scss'],
    host: {class: 'cell auto cell-block-y'}
})
export class CollaboratorConfigurationComponent implements OnInit {
    private static readonly NB_ITEMS_PER_PAGE: number = 10;

    @Input() isRefundManager: boolean = false;
    @Output() navigateToPermissionTabByName: EventEmitter<string> = new EventEmitter<string>();

    private foAccountRef;

    collaboratorsToKeep: Array<CollaboratorForm>;
    collaborators: Array<CollaboratorForm>;
    pageNumber: number = 1; // 1-based
    nbrCollaborators: number = 0;
    nbrPageMax: number = 0;
    searching: boolean;
    searchLimit = 100;
    private currentPerson: DetailedPersonDto;
    private searchCriteria: CollaboratorSearchCriteria = {};
    public hasCollaboratorsWriteRole: boolean;
    public hasSubscriberRolesReadRole: boolean;

    constructor(private readonly personHttpService: FrontPersonHttpService,
        private readonly foAccountHttpService: FrontFoAccountHttpService,
        private readonly mediaHttpService: FrontMediaHttpService,
        private readonly notificationService: NotificationService,
        private readonly currentUserContextService: CurrentUserContextService,
        private readonly route: ActivatedRoute,
        private readonly currentSubscriberRolesService: CurrentSubscriberRolesService) {
    }

    ngOnInit() {
        this.currentSubscriberRolesService
            .hasRole(buildRole(SubscriberRoleType.COLLABORATORS, SubscriberRoleLevel.WRITE))
            .subscribe(hasRole => this.hasCollaboratorsWriteRole = hasRole);

        this.currentSubscriberRolesService
            .hasRole(buildRole(SubscriberRoleType.SUBSCRIBER_ROLES, SubscriberRoleLevel.READ))
            .subscribe(hasRole => this.hasSubscriberRolesReadRole = hasRole);

        this.searching = true;
        this.initData();
    }

    initData() {
        this.route.data.pipe(
            map(data => data.detailedPerson),
            tap(person => this.foAccountRef = this.currentUserContextService.getCurrentFoAccountId()),
        ).subscribe(person => {
            this.currentPerson = person;
            this.countCollaborators();
            this.searchWithCriteria({}, true);
        });
    }

    private countCollaborators(): void {
        this.foAccountHttpService.countCollaborators(
            this.foAccountRef,
            this.searchCriteria,
            true
        ).subscribe((nbrSubscribers) => {
            this.nbrCollaborators = <number>nbrSubscribers;
            this.recomputeNbrPageMax(
                this.nbrCollaborators > this.searchLimit ? this.searchLimit : this.nbrCollaborators);
        });
    }

    search(pageNumber) {
        this.pageNumber = pageNumber;
        this.collaborators = slice(
            this.collaboratorsToKeep,
            (pageNumber - 1) * CollaboratorConfigurationComponent.NB_ITEMS_PER_PAGE,
            pageNumber * CollaboratorConfigurationComponent.NB_ITEMS_PER_PAGE
        );
    }

    private recomputeNbrPageMax(subscribersNumber: number) {
        this.nbrPageMax = subscribersNumber > CollaboratorConfigurationComponent.NB_ITEMS_PER_PAGE
            ? Math.ceil(subscribersNumber / CollaboratorConfigurationComponent.NB_ITEMS_PER_PAGE) : 1;
    }

    searchWithCriteria(searchCriteria?: CollaboratorSearchCriteria, resetPage: boolean = true) {
        this.searching = true;
        this.searchCriteria = searchCriteria;

        if (resetPage) {
            this.pageNumber = 1;
            this.countCollaborators();
        }

        const paginationParams: IndexPaginationParams = {
            limit: this.searchLimit + 1
        };

        this.foAccountHttpService.findCollaborators(
            this.foAccountRef,
            this.searchCriteria,
            true,
            true,
            paginationParams
        )
            .pipe(
                finalize(() => this.searching = false)
            )
            .subscribe((subscribers) => {
                this.collaboratorsToKeep = subscribers
                    .map(subscriber => {
                        return this.toCollaboratorForm(subscriber);
                    });
                this.collaborators = slice(
                    this.collaboratorsToKeep,
                    0,
                    CollaboratorConfigurationComponent.NB_ITEMS_PER_PAGE
                );
            });
    }

    private toCollaboratorForm(subscriber) {
        return ({
            _id: subscriber.id,
            name: subscriber.firstName,
            lastName: subscriber.lastName,
            email: subscriber.email,
            responsibility: subscriber.manager ? Responsibility.MANAGER : Responsibility.COLLABORATOR,
            withSupervisedStation: subscriber.withSupervisedStation,
            isRefundCollaborator: subscriber.refundCollaborator,
            medias: subscriber.mediaItems,
            mode: Mode.UPDATE,
            status: subscriber.status
        });
    }

    refreshCollaborator() {
        // Force angular refresh collaborators.
        this.collaborators = [].concat(this.collaborators);
    }
}
