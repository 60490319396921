<form class="has-space" [formGroup]="form">
    <fieldset>
        <legend>{{'media.importExport' | translate}}</legend>

        <div class="grid-x align-middle">
            <import-export
                [exportUrl]="'/api/foAccounts/' + foAccountRef + '/collaborators/export/xlsx' + getQueryParamsFromCriteria()"
                [uploadText]="'import.collaborator.button' | translate"
                [exportType]="'collaborator'"
                [importMaxErrorToReport]="undefined"
                [createImportRequestReportObs]="createCollaboratorImportRequestReportObs"
                [fetchImportRequestReportObs]="fetchImportRequestReport"
                [importRequestObs]="importRequestObs"
                [hasWriteRole]="hasCollaboratorsWriteRole"
                (importRequestProcessingEnded)="searchCollaborators()"
                (importEnded)="fetchLastReportInfo()"
                [isFlex]="!!lastImport"
                [ngClass]="{'auto _noMarginRight': lastImport}">
            </import-export>

            <hr *ngIf="lastImport">

            <div *ngIf="lastImport"
                class="cell shrink lastImport grid-x align-middle grid-padding-x">
                <div class="cell shrink grid-y">
                    <div class="cell shrink grid-x align-middle lastImport-title">
                        <div class="cell shrink lastImport-title-text">
                            {{'import.collaborator.lastImport' | translate}}
                        </div>
                        <div class="cell shrink">
                            <span class="status"
                                  [ngClass]="{'status-color-ERROR': lastImport.status === 'ERROR', 'status-color-SUCCESS': lastImport.status === 'SUCCESS'}">
                                {{'import.collaborator.lastImport.status.' + lastImport.status | translate}}
                            </span>
                        </div>
                    </div>

                    <div class="cell shrink lastImport-date">
                        {{lastImport.date | date:'dd/MM/YYYY HH:mm'}}
                    </div>
                </div>

                <a *ngIf="lastImport.status === 'ERROR'"
                   [href]="'/api/foAccounts/' + foAccountRef + '/collaborators/import/report/' + lastImport._id"
                   target="_blank"
                   class="cell shrink button secondary downloadReport">
                    {{'import.collaborator.downloadErrorReport' | translate}}
                </a>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend>{{'media.filter.title' | translate}}</legend>
        <div class="grid-x align-middle grid-padding-x grid-margin-y">
            <div class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.firstName && form.controls.firstName?.dirty && form.controls.firstName.value?.length > 0}">
                <input class="search" type="text"
                       placeholder="{{'config.collaborators.placeholder.firstName' | translate}}"
                       name="firstName" [formControlName]="'firstName'"
                       (keyup.enter)="searchCollaborators()">
                <i class="fas fa-search search-icon"></i>
            </div>
            <div class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.lastName && form.controls.lastName?.dirty && form.controls.lastName.value?.length > 0}">
                <input class="search" type="text"
                       placeholder="{{'config.collaborators.placeholder.lastName' | translate}}"
                       name="lastName" [formControlName]="'lastName'"
                       (keyup.enter)="searchCollaborators()">
                <i class="fas fa-search search-icon"></i>
            </div>
            <div class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.email && form.controls.email?.dirty && form.controls.email.value?.length > 0}">
                <input class="search" type="text"
                       placeholder="{{'config.collaborators.placeholder.email' | translate}}"
                       name="email" [formControlName]="'email'"
                       (keyup.enter)="searchCollaborators()">
                <i class="fas fa-search search-icon"></i>
            </div>
            <div class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.withPass && form.controls.withPass?.dirty && form.controls.withPass.value?.length > 0}">
                <select [formControlName]="'withPass'"
                        name="withPass">
                    <option [value]="''">{{'config.collaborators.placeholder.pass' | translate}}</option>
                    <option [value]="true">{{"config.collaborators.pass.withPass" | translate}}</option>
                    <option [value]="false">{{"config.collaborators.pass.withoutPass" | translate}}</option>
                </select>
            </div>
            <div *ngIf="!fleetConfig?.managerCreationOnly"
                 class="cell small-8 xxlarge-auto search-label"
                 [ngClass]="{validate : form.controls.role && form.controls.role?.dirty && form.controls.role.value?.length > 0}">
                <select [formControlName]="'role'"
                        name="role">
                    <option [value]="''">{{'config.collaborators.placeholder.responsibility' | translate}}</option>
                    <option
                        [value]="Responsibility.COLLABORATOR">{{"config.collaborators.responsibility.collaborator" | translate}}</option>
                    <option
                        [value]="Responsibility.MANAGER">{{"config.collaborators.responsibility.manager" | translate}}</option>
                </select>
            </div>
            <div class="cell small-8 xxlarge-auto search-label">
                <label>
                    <select [formControlName]="'status'">
                        <option [value]="''">{{'config.collaborators.placeholder.status' | translate}}</option>
                        <option
                            [value]="DetailedCollaboratorStatus.WAITING_FOR_ACTIVATION">{{"config.collaborator.status.WAITING_FOR_ACTIVATION" | translate}}</option>
                        <option
                            [value]="DetailedCollaboratorStatus.ACTIVE">{{"config.collaborator.status.ACTIVE" | translate}}</option>
                        <option
                            [value]="DetailedCollaboratorStatus.INACTIVE">{{"config.collaborator.status.INACTIVE" | translate}}</option>
                    </select>
                </label>
            </div>

            <div class="cell small-8 xxlarge-shrink">
                <button class="button primary _fullWidth" type="button" (click)="searchCollaborators()">
                    {{'collaborator.search.title' | translate}}
                </button>
            </div>
        </div>
        <div class="grid-x align-middle align-justify grid-padding-x grid-padding-y force-space-top-12">
            <div class="cell small-18 xxxlarge-shrink">
                <div class="info common-block force-space-bottom-0"
                     *ngIf="nbrTotalCollaborators > searchLimit">
                    {{'config.collaborator.search.limit' | translate}}
                </div>
            </div>
        </div>
    </fieldset>

    <ng-container>
        <div class="space-top-25 space-bottom-25 grid-x align-middle">
            <next-last-page class="small-2" *ngIf="!searching" [pageNumber]="pageNumber" [maxPage]="nbrPageMax"
                            (changePage)="pageChange.emit($event)"></next-last-page>
            <indicator class="cell small-4" [img]="'users'">
                <ng-container value>{{nbrTotalCollaborators | number}}</ng-container>
            </indicator>
            <div class="cell auto grid-x align-right">
                <button type="button" class="addButton cell small-4 button secondary color add"
                        *ngIf="hasCollaboratorsWriteRole"
                        (click)="addItemForm()"
                        [disabled]="!canAddCollaborator">
                    {{'config.collaborators.add' | translate}}
                </button>
            </div>

        </div>
    </ng-container>

    <ng-container [formArrayName]="collaboratorsFormName" *ngIf="fleetConfig">
        <div class="grid-x grid-margin-x">
            <div class="cell account-list-header">
                <div class="grid-x grid-padding-x align-middle account-list-header-content">
                    <div [ngClass]="{'small-3': showResponsibilities || isRefundManager, 'small-5': !showResponsibilities && !isRefundManager,
                                     'small-4': !showResponsibilities || !isRefundManager}"
                         class="cell account-list-header-item">{{'config.collaborator.name.title' | translate}}</div>

                    <div [ngClass]="{'small-3': showResponsibilities || isRefundManager, 'small-5': !showResponsibilities && !isRefundManager,
                                     'small-4': !showResponsibilities || !isRefundManager}"
                         class="cell account-list-header-item">{{'config.collaborator.lastname.title' | translate}}</div>

                    <div [ngClass]="{'small-3': showResponsibilities || isRefundManager, 'small-5': !showResponsibilities && !isRefundManager,
                                     'small-4': !showResponsibilities || !isRefundManager}"
                         class="cell  account-list-header-item">{{'config.collaborator.email.title' | translate}}</div>

                    <div [ngClass]="{'small-3': showResponsibilities || isRefundManager, 'small-5': !showResponsibilities && !isRefundManager,
                                     'small-4': !showResponsibilities || !isRefundManager}"
                         class="cell account-list-header-item">{{'config.collaborator.media.title' | translate}}</div>

                    <div *ngIf="isRefundManager"
                         class="cell small-4 account-list-header-item">
                        {{'config.collaborator.expenseReportStation.title' | translate}}
                        <span data-tooltip
                              class="top"
                              title="{{'config.collaborator.expenseReportStation.tooltip' |
                                translate: {supervisedStationCount: supervisedStationCount} }}">
                            <i class="fa fa-info-circle">
                            </i>
                        </span>
                        <div>{{collaboratorWithSupervisedStationCount}} / {{supervisedStationCount}}</div>
                    </div>

                    <div *ngIf="showResponsibilities"
                         class="cell small-3 account-list-header-item">{{'config.collaborator.responsibility.title' | translate}}</div>
                    <div class="cell small-2 account-list-header-item">
                        {{'config.collaborator.status.title' | translate}}
                        <span data-tooltip
                              class="top"
                              title="{{'config.collaborator.status.tooltip' | translate}}">
                            <i class="fa fa-info-circle">
                            </i>
                        </span>
                    </div>
                    <div *ngIf="hasCollaboratorsWriteRole" class="cell small-3 text-center account-list-header-item">{{'config.collaborator.actions.title' | translate}}</div>
                </div>
            </div>
        </div>

        <div class="grid-x" *ngIf="!searching else spinner">
            <div class="cell align-middle space-top-25"
                 *ngFor="let collaboratorEntry of collaboratorsForm.controls;let first = first;let last = last; let index = index">
                <div class="grid-x grid-margin-x">
                    <collaborator-form class="cell"
                                       [formControlName]="index"
                                       [responsibilities]="responsibilities"
                                       [hasCollaboratorsWriteRole]="hasCollaboratorsWriteRole"
                                       [hasSubscriberRolesReadRole]="hasSubscriberRolesReadRole"
                                       [showResponsibility]="showResponsibilities"
                                       [isRefundManager]="isRefundManager"
                                       [collaboratorWithSupervisedStationCount]="collaboratorWithSupervisedStationCount"
                                       [supervisedStationCount]="supervisedStationCount"
                                       [mediasObs]="mediasObs"
                                       [medias]="collaboratorEntry?.value?.medias"
                                       [searchLimitMedia]="searchLimitMedia"
                                       [addingUser]="addingUser"
                                       [deletingUser]="deletingUser"
                                       [collaboratorEntry]="collaboratorEntry"
                                       [status]="collaboratorEntry?.value?.status"
                                       (addUserToFleetRequested)="addUserToFleetRequest(index)"
                                       (redirectedAndFilteredOnPermissionTab)="redirectAndFilterOnPermissionTab(collaboratorEntry)"
                                       (collaboratorDeleted)="deleteCollaborator(index, collaboratorEntry?.value?.responsibility)"
                                       (withSupervisedStationChanged)="withSupervisedStationChanged($event)"></collaborator-form>

                    <span class="cell error note" [hidden]="!(form.invalid && form.errors?.mailCollaborators)">
                        {{'config.collaborators.mail.error' | translate}}
                    </span>
                </div>
            </div>
        </div>
    </ng-container>

    <next-last-page *ngIf="!searching" [pageNumber]="pageNumber" [maxPage]="nbrPageMax"
                    (changePage)="pageChange.emit($event)"></next-last-page>
</form>

<ng-template #spinner>
    <div class="app-loader small text-center">
        <span class="spinner small"></span>
    </div>
</ng-template>
