import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    ACCEPTED_FILE_FORMATS,
    FrontUploadHttpService,
    FrontVehicleVersionHttpService,
    FrontPersonPortalContextService,
    Vehicle,
    VehicleAcquisitionType,
    VehicleMakeDto,
    VehicleModelDto,
    VehicleUsageType,
    VehicleVersionDto,
    FrontMediaHttpService, MediaPublicCodeDto, FrontVehicleHttpService
} from 'lib-front';
import {debounceTime, finalize, shareReplay, switchMap, takeUntil} from 'rxjs/operators';
import {noop, of, Subject, Subscription} from 'rxjs';
import {NotificationService} from '../../services/utils/notification.service';
import {Router} from '@angular/router';
import {FleetVehicleUtils} from '../fleetVehicleUtils';
import { carRegistrationFileExtensionValidator } from '../../directives/validators/carRegistrationFileExtensionValidator';
import {cloneDeep} from 'lodash-es';
import {CurrentUserContextService} from '../../services/business/currentUserContext.service';
import {AlertService} from '../../services/utils/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';

@Component({
    selector: 'fleet-vehicle-form',
    templateUrl: './fleet-vehicle-form.component.html',
    styleUrls: ['./fleet-vehicle-form.component.scss'],
})
export class FleetVehicleFormComponent implements OnInit, OnDestroy {
    private readonly baseUrl: string = '/main/configuration/fleet';

    @Input() get vehicle(): Vehicle {
        return this._vehicle;
    };
    set vehicle(vehicle: Vehicle) {
        this._vehicle = vehicle;
        this._carRegistrationFileBkp = this.vehicle.carRegistrationFile;

        if (vehicle.id) {
            this.mode = 'EDIT';
        } else {
            this.mode = 'CREATE';
            this.isEdit = true;
        }
    }

    public isRigeUser: boolean;

    isEdit: boolean = false;
    destroy$: Subject<void> = new Subject();
    showOldMakeValue: boolean = true;
    showOldModelValue: boolean = true;
    showOldVersionValue: boolean = true;
    VehicleUsageType = VehicleUsageType;
    VehicleAcquisitionType = VehicleAcquisitionType;
    currentVehicleIdentifierNumber: string;

    form: UntypedFormGroup;
    vehicleVersionForm: UntypedFormGroup;
    makeCtrl: UntypedFormControl;
    licenseNumberCtrl: UntypedFormControl;
    vehicleIdentifierNumberCtrl: UntypedFormControl;
    carRegistrationDocumentCtrl: UntypedFormControl;

    mediasCanActivate: MediaPublicCodeDto[] = [];
    mediaCanActivateExist: boolean | undefined = undefined;

    vehicleMakes: VehicleMakeDto[];
    vehicleModels: VehicleModelDto[];
    vehicleVersions: VehicleVersionDto[];

    mode: 'EDIT' | 'CREATE';

    foAccountRef: string;

    private mediaCanActivateSubject: Subject<string> = new Subject();
    private mediaCanActivateSubscription: Subscription;
    private _vehicle: Vehicle;
    private _carRegistrationFileBkp: any;
    isCreating: boolean = false;
    isModifying: boolean = false;

    constructor(private readonly fb: UntypedFormBuilder,
        private readonly notificationService: NotificationService,
        private readonly vehicleVersionHttpService: FrontVehicleVersionHttpService,
        private readonly uploadHttpService: FrontUploadHttpService,
        private readonly personPortalContextService: FrontPersonPortalContextService,
        private readonly mediaService: FrontMediaHttpService,
        private readonly router: Router,
        private readonly vehicleHttpService: FrontVehicleHttpService,
        private readonly currentUserContextService: CurrentUserContextService,
        private readonly alertService: AlertService,
        private readonly translateService: TranslateService,
        private readonly location: Location,
        @Inject(ACCEPTED_FILE_FORMATS) public readonly fileFormats: string[]) {
    }

    ngOnInit(): void {
        this.isModifying = history.state.isModifying;

        this.mediaCanActivateSubscription = this.mediaCanActivateSubject.pipe(
            debounceTime(200),
            switchMap((mediaCode) => this.mediaService.findLightMedias(
                {
                    foAccountId: this.foAccountRef,
                    partialPublicCode: mediaCode,
                    withDeactivated: false,
                    withoutLinkedToVehicle: true,
                    limit: 20
                }
            ))
        ).subscribe(mediaPublicCodeDtos => {
            this.mediasCanActivate = this._vehicle.mediaUsages.length ? [...this._vehicle.mediaUsages, ...mediaPublicCodeDtos] : mediaPublicCodeDtos;
            if (!this.mediaCanActivateExist) {
                this.mediaCanActivateExist = this.mediasCanActivate.length > 0;
            }
        });

        this.personPortalContextService.isRigeUser().subscribe(
            isRigeUser => {
                this.isRigeUser = isRigeUser;
                this.initFormData();
            }
        );

        this.vehicleVersionHttpService.findAllMakes()
            .subscribe((makes) => this.vehicleMakes = makes);


        this.foAccountRef = this.currentUserContextService.getCurrentFoAccountId();
    }

    private initFormData() {
        if (this.isRigeUser) {
            this.makeCtrl = this.fb.control(this._vehicle.vehicleVersion.make, Validators.required);
        } else {
            this.makeCtrl = this.fb.control(this._vehicle.vehicleVersion.make);
        }

        this.vehicleVersionForm = this.fb.group({
            make: this.makeCtrl,
            model: this._vehicle.vehicleVersion.model,
            version: this._vehicle.vehicleVersion.version
        });

        this.licenseNumberCtrl = this.fb.control(this._vehicle.licenseNumber);
        this.vehicleIdentifierNumberCtrl = this.fb.control(this._vehicle.vehicleIdentifierNumber);
        this.carRegistrationDocumentCtrl = this.fb.control(null, carRegistrationFileExtensionValidator(this.fileFormats));
        this.form = this.fb.group({
            vehicleVersion: this.vehicleVersionForm,
            licenseNumber: this.licenseNumberCtrl,
            vehicleIdentifierNumber: this.vehicleIdentifierNumberCtrl,
            vehicleUsageType: this._vehicle.vehicleUsageType,
            vehicleAcquisitionType: this._vehicle.vehicleAcquisitionType,
            leasingPartner: this._vehicle.leasingPartner,
            detail: this._vehicle.detail,
            media: this._vehicle.mediaUsages?.length ? this._vehicle.mediaUsages : [''],
            priority: this._vehicle.priority,
            carRegistrationFile: this.carRegistrationDocumentCtrl
        });

        this.form.get('vehicleVersion.model').disable({emitEvent: false});
        this.form.get('vehicleVersion.version').disable({emitEvent: false});
        this.form.get('priority').disable({emitEvent: false});

        this.makeCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.onMakeChange());
        this.form.get('vehicleVersion.model').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.onModelChange());

        if (this.isModifying) {
            this.editVehicle();
        }
    }

    private onMakeChange() {
        if (!this.isEdit) {
            return;
        }

        this.showOldMakeValue = false;
        this.showOldModelValue = false;
        const make: string = this.form.get('vehicleVersion.make').value;

        if (make) {
            this.vehicleVersionHttpService.findModelsByMake(make)
                .pipe(shareReplay(1))
                .subscribe(models => {
                    this.vehicleModels = models;
                    const model: AbstractControl = this.form.get('vehicleVersion.model');

                    if (models.length && model.disabled) {
                        model.enable();
                        if (this.isRigeUser) {
                            model.setValidators(Validators.required);
                        }
                    } else if (!models.length) {
                        model.disable();
                        model.clearValidators();
                    }
                    model.reset();
                    model.setValue(this._vehicle.vehicleVersion.model, {emitEvent: false});
                });
        } else {
            this.form.get('vehicleVersion.model').setValue('');
        }
    }

    private onModelChange() {
        if (!this.isEdit) {
            return;
        }

        this.showOldVersionValue = false;
        const make: string = this.form.get('vehicleVersion.make').value;
        const model: string = this.form.get('vehicleVersion.model').value;

        if (make && model) {
            this.vehicleVersionHttpService
                .findVersionsByMakeModel(make, model)
                .pipe(shareReplay(1))
                .subscribe((versions => {
                    this.vehicleVersions = versions;
                    const version: AbstractControl = this.form.get('vehicleVersion.version');

                    if (versions.length && version.disabled) {
                        version.enable();
                    } else if (!versions.length) {
                        version.disable();
                        version.clearValidators();
                    }

                    version.reset();

                    let currentVersion = this._vehicle.vehicleVersion.version;
                    /**
                     * Setting version to initial is a workaround used to be able to select standard vehicle version
                     * without breaking anything in the mobile app.
                     * An issue was open to do that in a better way: https://issues.4sh.fr/browse/SMBP-10790
                     */
                    version.setValue((currentVersion || currentVersion == '') ? currentVersion : 'initial', {emitEvent: false});
                }));
        } else {
            this.form.get('vehicleVersion.version').setValue('initial');
        }
    }

    public deleteVehicle(vehicle: Vehicle) {
        let deletionValidated: boolean;
        this.alertService.confirm(
            this.translateService.instant('fleet.vehicle.delete.message'),
            this.translateService.instant('fleet.vehicle.delete.title'),
            this.translateService.instant('fleet.vehicle.delete.validate'),
            this.translateService.instant('fleet.vehicle.delete.cancel')
        ).pipe(
            switchMap((response) => {
                deletionValidated = response;
                if (response) {
                    return this.vehicleHttpService.deleteVehicle(vehicle.id);
                }
                return of(noop);
            })
        ).subscribe(() => {
            if (deletionValidated) {
                this.notificationService.success('fleet.delete');
                this.goToFleetList();
            }
        }, () => {
            this.notificationService.error('fleet.error');
        });
    }

    public editVehicle() {
        // initFormData call editVehicle if isModifying is true.
        // So set it to false to ensure to avoid circle calls.
        this.isModifying = false;
        this.initFormData();

        this.isEdit = !this.isEdit;
        this.isModifying = true;
        this.form.get('media').setValue(this._vehicle.mediaUsages);
        this.mediaCanActivateExist = false;
        this.mediaCanActivateSubject.next(null);
        this.form.get('priority').enable();
        this.currentVehicleIdentifierNumber = this.form.value.vehicleIdentifierNumber?.toLowerCase();

        this.form.get('vehicleVersion.make').setValue(this._vehicle.vehicleVersion?.make);

    }

    public cancelEditVehicle() {
        this.vehicle.carRegistrationFile = this._carRegistrationFileBkp;
        this.form.reset();
        this.isEdit = false;
        this.isModifying = false;
    }

    public updateVehicle() {
        this.isEdit = !this.isEdit;
        this.isModifying = false;

        const vehicleToUpdate: Vehicle = {
            id: this._vehicle.id,
            vehicleVersion: {
                make: this.form.value.vehicleVersion.make,
                model: this.form.value.vehicleVersion.model != null ? this.form.value.vehicleVersion.model : this._vehicle.vehicleVersion?.model,
                version: this.form.value.vehicleVersion.version != null ? this.form.value.vehicleVersion.version : this._vehicle.vehicleVersion?.version,
            },
            priority: this.form.value.priority,
            licenseNumber: this.form.value.licenseNumber,
            vehicleIdentifierNumber: this.form.value.vehicleIdentifierNumber !== '' ?
                this.form.value.vehicleIdentifierNumber : this.currentVehicleIdentifierNumber,
            detail: this.form.value.detail,
            vehicleUsageType: this.form.value.vehicleUsageType,
            vehicleAcquisitionType: this.form.value.vehicleAcquisitionType,
            leasingPartner: this.form.value.leasingPartner,
            mediaId: this.form.value.media?.mediaId,
            carRegistrationFile: this._vehicle.carRegistrationFile,
            timezone: this._vehicle.timezone,
            externalVehicleRefByServiceProvider: this._vehicle.externalVehicleRefByServiceProvider,
            externalMediaRefByServiceProvider: this._vehicle.externalMediaRefByServiceProvider,
            mediaUsages: this.form.value.media
        };

        console.log(vehicleToUpdate);

        if (vehicleToUpdate.vehicleVersion.version === 'initial') {
            vehicleToUpdate.vehicleVersion.version = null;
        }

        const files: FileList = this.form.value.carRegistrationFile;
        if (files && files.length) {
            this.uploadHttpService.upload(files[0], files[0].name)
                .subscribe(
                    fileReference => {
                        vehicleToUpdate.carRegistrationFile = {id: fileReference.fileId,
                            name: files[0].name
                        };
                        this.cleanUpload();
                        this.saveVehicle(vehicleToUpdate);
                    },
                    () => this.notificationService.error('upload.error')
                );
        } else {
            this.saveVehicle(vehicleToUpdate);
        }

        this.form.get('vehicleVersion.model').disable({emitEvent: false});
        this.form.get('vehicleVersion.model').markAsUntouched();
        this.form.get('vehicleVersion.model').markAsPristine();
        this.form.get('vehicleVersion.version').disable({emitEvent: false});
        this.form.get('priority').disable();
    }

    public trackByName(index: number, vehicle: VehicleMakeDto | VehicleModelDto) {
        return vehicle?.name ?? index;
    }

    public trackByVehicleVersionId(index: number, vehicleVersion: VehicleVersionDto) {
        return vehicleVersion?.id ?? index;
    }

    public editPlanning() {
        this.router.navigate([`${this.baseUrl}/planning/${this._vehicle.id}`], {
            queryParams: { licenseNumber: this._vehicle.licenseNumber, timezone: this._vehicle.timezone }
        });
    }

    public isVehicleCreatedInDreevProvider(): boolean {
        return FleetVehicleUtils.isVehicleCreatedInDreevProvider(this._vehicle);
    }

    public isVehicleMediaAssociatedInDreevProvider(vehicle: Vehicle, mediaId: string): boolean {
        return FleetVehicleUtils.isVehicleMediaAssociatedInDreevProvider(vehicle, mediaId);
    }

    public sortNull() {
        return FleetVehicleUtils.sortNull();
    }

    public searchMedias(mediaCode: string) {
        this.mediaCanActivateSubject.next(mediaCode);
    }

    ngOnDestroy(): void {
        this.mediaCanActivateSubscription.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    public validateVehicleCreation() {
        this.isCreating = true;

        const files: FileList = this.form.get('carRegistrationFile').value;
        if (files && files.length) {
            this.uploadHttpService.upload(files[0], files[0].name)
                .subscribe(
                    fileReference => {
                        this.form.get('carRegistrationFile').setValue(
                            {id: fileReference.fileId,
                                name: files[0].name
                            });
                        this.addVehicleForm();
                    },
                    () => {
                        this.notificationService.error('upload.error');
                        this.isCreating = false;
                    }
                );
        } else {
            this.addVehicleForm();
        }
    }

    private addVehicleForm() {
        const vehicleFormToAdd: UntypedFormGroup = cloneDeep(this.form);
        if (vehicleFormToAdd.get('vehicleVersion.version').value === 'initial') {
            vehicleFormToAdd.get('vehicleVersion.version').setValue(null);
        }
        this.createVehicle(vehicleFormToAdd);
    }

    private createVehicle(controlToAdd: UntypedFormGroup) {
        this.isCreating = true;
        this.vehicleHttpService.createVehicle(controlToAdd.value)
            .pipe(
                finalize(() => this.isCreating = false)
            )
            .subscribe({
                next: (vehicle) => {
                    this.notificationService.success('fleet.create.default');

                    this.cleanUpload();
                    this._carRegistrationFileBkp = this.vehicle.carRegistrationFile;

                    const formControl = this.fb.control(vehicle);
                    if (controlToAdd.value.media) {
                        this.vehicleHttpService.addMediaToVehicle(vehicle.id, controlToAdd.value.media.mediaId)
                            .subscribe(() => {
                                formControl.value.mediaUsages = [controlToAdd.value.media];
                            },
                            () => {
                                this.notificationService.error('fleet.vehicle.media.error');
                            });
                    }
                    this.location.go('/main/configuration/fleet');
                },
                error: _ => {
                    this.notificationService.error('fleet.error');
                }
            });
    }

    public saveVehicle(vehicle: Vehicle) {
        this.vehicleHttpService.updateVehicle(vehicle.id, vehicle)
            .subscribe({
                next: _ => {
                    this.notificationService.success('fleet.update');

                    this._carRegistrationFileBkp = vehicle.carRegistrationFile;

                    if (!!vehicle.mediaId) {
                        this.vehicleHttpService.addMediaToVehicle(vehicle.id, vehicle.mediaId)
                            .subscribe(() => {
                                this._vehicle = vehicle;
                            });
                    } else {
                        this.vehicleHttpService.removeMediaFromVehicle(vehicle.id, vehicle.mediaId)
                            .subscribe(() => {
                                this._vehicle = vehicle;
                            });
                    }
                },
                error: _ => {
                    this.notificationService.error('fleet.error');
                }
            });
    }

    goToFleetList() {
        this.location.go('/main/configuration/fleet');
    }

    private cleanUpload() {
        // Empty upload component
        this.form.get('carRegistrationFile').setValue(null);
    }
}
